(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

document.addEventListener("DOMContentLoaded", function () {
  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./modules/target-blank.js');
  require('./modules/header.js');
  require('./modules/menu.js');
  require('./modules/slider.js');
  require('./modules/loadmore.js');
  // require('./modules/quiz.js');
  require('./modules/dropdown.js');
  require('./modules/aside-menu.js');
  require('./modules/g-translate.js');
});

// remove preloader
window.addEventListener('load', function () {
  $('.preloader').fadeOut();
  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart'
  });
});

},{"./modules/aside-menu.js":2,"./modules/dropdown.js":3,"./modules/g-translate.js":4,"./modules/header.js":5,"./modules/loadmore.js":6,"./modules/menu.js":7,"./modules/slider.js":8,"./modules/target-blank.js":9}],2:[function(require,module,exports){
"use strict";

var element = document.querySelector('.aside-menu');
if (element) {
  var convertString = function convertString(input) {
    return input.toLowerCase().trim().replace(/[^a-zа-яё0-9\s]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
  };
  var container = element.parentElement;
  var setWidth = function setWidth() {
    element.style.width = container.clientWidth + 'px';
  };
  var listCalculate = function listCalculate() {
    var content = container.previousElementSibling;
    var headers = content.querySelectorAll('h3');
    if (headers.length > 0) {
      var menuList = container.querySelector('ul');
      headers.forEach(function (header) {
        var text = header.textContent;
        var id = convertString(text);
        header.setAttribute('id', id);
        var listItem = document.createElement('li');
        var link = document.createElement('a');
        link.href = "#".concat(id);
        link.textContent = text;
        listItem.appendChild(link);
        menuList.appendChild(listItem);
      });
    }
  };
  var positionCalculate = function positionCalculate() {
    var containerTop = container.getBoundingClientRect().top;
    var containerBot = container.getBoundingClientRect().bottom;
    var elementHeight = element.getBoundingClientRect().height;
    if (containerTop > 100) {
      element.classList.remove('scrolling', 'bot');
      element.classList.add('top');
    } else if (containerTop <= 100 && (window.innerWidth > 992 ? containerBot > elementHeight + 100 : true)) {
      element.classList.remove('top', 'bot');
      element.classList.add('scrolling');
    } else if (window.innerWidth > 992 && containerBot <= elementHeight + 100) {
      element.classList.remove('scrolling', 'top');
      element.classList.add('bot');
    }
  };
  var menuOpener = function menuOpener() {
    var header = element.querySelector('h3');
    header.addEventListener('click', function () {
      if (window.innerWidth < 992) {
        if (!element.classList.contains('opened')) {
          element.classList.add('opened');
        } else {
          element.classList.remove('opened');
        }
      }
    });
  };
  window.addEventListener('load', function () {
    menuOpener();
    setWidth();
    listCalculate();
    positionCalculate();
  });
  window.addEventListener('resize', function () {
    setWidth();
  });
  window.addEventListener('scroll', function () {
    positionCalculate();
  });
  document.addEventListener('click', function (e) {
    if (window.innerWidth < 992) {
      if (!e.target.closest('.aside-menu')) {
        element.classList.remove('opened');
      }
    }
  });
}

},{}],3:[function(require,module,exports){
"use strict";

var dropdowns = document.querySelectorAll('.dropdown__item');
if (dropdowns.length > 0) {
  dropdowns.forEach(function (dropdown) {
    var header = dropdown.querySelector('.dropdown__item-header');
    if (header) {
      header.addEventListener('click', function () {
        if (dropdown.classList.contains('opened')) {
          dropdown.classList.remove('opened');
        } else {
          dropdown.classList.add('opened');
        }
      });
    }
  });
}

},{}],4:[function(require,module,exports){
"use strict";

var langBtns = document.querySelectorAll('.lang-btn');
var curLang = document.querySelector('html');
var selectorCurLang = document.querySelector('.lang-selector__cur');
var langList = [];
if (langBtns.length > 0) {
  langBtns.forEach(function (btn) {
    langList.push({
      lang: btn.textContent,
      "short": btn.getAttribute('data-lang')
    });
  });
  window.addEventListener('load', function () {
    setTimeout(function () {
      var _langList$find;
      var lang = curLang.getAttribute('lang');
      if (lang == 'en-US') {
        lang = 'en';
      }
      var result = (_langList$find = langList.find(function (item) {
        return item["short"] === lang;
      })) === null || _langList$find === void 0 ? void 0 : _langList$find.lang;
      selectorCurLang.textContent = result;
      langBtns.forEach(function (btn) {
        if (btn.getAttribute('data-lang') == lang) {
          btn.classList.add('active');
        }
      });
    }, 500);
    langBtns.forEach(function (btn) {
      btn.addEventListener('click', function () {
        if (!btn.classList.contains('active')) {
          var lang = btn.getAttribute('data-lang');
          langBtns.forEach(function (btn) {
            if (btn.classList.contains('active')) {
              btn.classList.remove('active');
            }
          });
          btn.classList.add('active');
          var select = document.querySelector('select.goog-te-combo');
          if (select) {
            select.value = lang;
            select.dispatchEvent(new Event('change'));
            selectorCurLang.textContent = btn.textContent;
            setTimeout(function () {
              if (curLang.getAttribute('lang') && curLang.getAttribute('lang') === 'auto') {
                select.value = lang;
                select.dispatchEvent(new Event('change'));
              }
            }, 100);
          }
        }
      });
    });
  });
}

},{}],5:[function(require,module,exports){
"use strict";

(function () {
  var $header = document.querySelector('.header');
  if ($header) {
    var stickyHeader = function stickyHeader() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // downscroll code
        $header.classList.remove('scrolled--visible');
        $header.style.top = '';
      } else {
        // upscroll code
        $header.classList.add('scrolled--visible');
        $header.style.top = '';
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

      $header.classList.toggle('scrolled', window.pageYOffset > sticky);
      if (window.pageYOffset <= sticky) {
        $header.classList.remove('scrolled--visible', 'scrolled');
        $header.style.top = top - window.pageYOffset + 'px';
      }
    };
    var topStr = window.getComputedStyle($header, null).getPropertyValue("top");
    var top = topStr.substr(0, topStr.length - 2);
    var lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var sticky = document.body.scrollTop + $header.offsetTop;
    window.addEventListener('scroll', stickyHeader);
    window.addEventListener('load', stickyHeader);
  }
})();

},{}],6:[function(require,module,exports){
"use strict";

var loadMoreBtns = document.querySelectorAll('.js-loadmore');
var tilesWraps = document.querySelectorAll('.tiles-wrap .tiles');
var itemsOnLoad = 8;
if (window.innerWidth < 992) {
  itemsOnLoad = 4;
}
if (tilesWraps.length > 0) {
  tilesWraps.forEach(function (wrap) {
    var items = wrap.children;
    for (var i = itemsOnLoad; i < items.length; i++) {
      items[i].style.display = 'none';
    }
  });
}
if (loadMoreBtns.length > 0) {
  loadMoreBtns.forEach(function (btn) {
    var wrap = btn.closest('.tiles-wrap');
    var tiles = wrap.querySelectorAll('.tiles > *');
    if (tiles.length > itemsOnLoad) {
      btn.addEventListener('click', function () {
        tiles.forEach(function (tile) {
          tile.style.display = '';
        });
        btn.remove();
      });
    } else {
      btn.remove();
    }
  });
}

},{}],7:[function(require,module,exports){
"use strict";

;
(function () {
  var $html = document.querySelector('html');
  var $body = document.querySelector('body');
  var $menu = document.querySelector('.header__nav');
  var $hamburger = document.querySelector('.toggle-menu');
  $hamburger.addEventListener('click', function () {
    this.classList.toggle('opened');
    $menu.classList.toggle('opened');
    if (this.classList.contains('opened')) {
      $body.style.overflow = 'hidden';
      $html.style.overflow = 'hidden';
    } else {
      $body.style.overflow = '';
      $html.style.overflow = '';
    }
  });
  var menuElements = document.querySelectorAll('.header__nav-list > .menu-item-has-children');
  var screenWidth = window.innerWidth;
  if (screenWidth > 991 && menuElements.length > 0) {
    menuElements.forEach(function (element) {
      var elementPosition = element.getBoundingClientRect().left;
      if (elementPosition > screenWidth / 2) {
        element.classList.add('openToLeft');
      }
    });
  }
  if (screenWidth < 991) {
    var links = document.querySelectorAll('.header__nav a');
    if (links.length > 0) {
      links.forEach(function (link) {
        link.addEventListener('click', function (e) {
          var menuItem = link.closest('.menu-item');
          var subMenu = menuItem.querySelector('.sub-menu');
          var back = document.createElement('div');
          back.classList.add('back-btn');
          back.innerHTML = link.innerHTML;
          subMenu.appendChild(back);
          if (menuItem.classList.contains('menu-item-has-children')) {
            e.preventDefault();
            menuItem.classList.add('opened');
          }
        });
      });
    }
    document.addEventListener('click', function (e) {
      var target = e.target;
      if (target.classList.contains('back-btn')) {
        var opened = target.closest('.menu-item.opened');
        opened.classList.remove('opened');
        target.remove();
      }
    });
  }
})();

},{}],8:[function(require,module,exports){
"use strict";

$('.slider').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  responsive: [{
    breakpoint: 1159,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 568,
    settings: {
      centerMode: true,
      centerPadding: '44px',
      slidesToShow: 1
    }
  }]
});

},{}],9:[function(require,module,exports){
"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
// add target blank to external links
var siteUrl = window.location.hostname;
var links = document.querySelectorAll('a[href*="//"]:not([href*="' + siteUrl + '"])');
var _iterator = _createForOfIteratorHelper(links),
  _step;
try {
  for (_iterator.s(); !(_step = _iterator.n()).done;) {
    var elem = _step.value;
    elem.setAttribute('target', '_blank');
    elem.setAttribute('rel', 'noopener noreferrer');
  }
} catch (err) {
  _iterator.e(err);
} finally {
  _iterator.f();
}

},{}]},{},[1]);
